import React from 'react'
import cx from 'classnames'
import Link from '../links/LinkLocalized'
import useTranslations from '../../../hooks/useTranslations'

const SitemapItem = ({
  to,
  className,
  name,
  isExternal,
  href,
  literal,
  status,
}) => {
  const t = useTranslations()

  return (
    <>
      {to ? (
        <Link
          className={cx('sitemap-item', { [className]: className })}
          key={name}
          to={to}
        >
          {literal ? name : t(name)}
          {status ? <span className="status-circle"></span> : null}
        </Link>
      ) : isExternal ? (
        <a
          className={cx(`sitemap-item ${className}`)}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {literal ? name : t(name)}
          {status ? <span className="status-circle"></span> : null}
        </a>
      ) : (
        <p className={cx('sitemap-item', { [className]: className })}>
          {literal ? name : t(name)}
          {status ? <span className="status-circle"></span> : null}
        </p>
      )}
    </>
  )
}

export default SitemapItem

import React from 'react'

const FooterAward = ({ image, title, subtitle, imageWidth, imageHeight }) => {
  return (
    <div className="footer-award">
      <img
        src={image}
        alt={title}
        width={imageWidth}
        height={imageHeight}
        loading="lazy"
      />
      <p className="footer-award-title">{title}</p>
      <p className="footer-award-subtitle">{subtitle}</p>
    </div>
  )
}

export default FooterAward

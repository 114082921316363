import React from 'react'
import PropTypes from 'prop-types'
import SitemapItem from './SitemapItem'
import cx from 'classnames'

const Footer = ({ items, isCol }) => {
  return (
    <div className={cx('footer-sitemap-block', { 'is-col': isCol })}>
      {items.map(item => (
        <SitemapItem
          key={item.name}
          to={item.to}
          className={item.className}
          name={item.name}
          isExternal={item.isExternal}
          href={item.href}
          literal={item.literal}
          status={item.status}
        />
      ))}
    </div>
  )
}

Footer.propTypes = {
  items: PropTypes.array,
  isCol: PropTypes.bool,
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import award1 from '../../../assets/images/blue-tulip-awards.svg'
import award2 from '../../../assets/images/startup-disruption-award.svg'
import award3 from '../../../assets/images/beautiful-software-awards.svg'
import { ReactComponent as RibbonLeft } from '../../../assets/icons/icon-award-ribbon-left.svg'
import { ReactComponent as RibbonRight } from '../../../assets/icons/icon-award-ribbon-right.svg'
import FooterAward from './FooterAward'
import SitemapBlock from './SitemapBlock'
import heart from '../../../assets/images/heart.png'

const Footer = ({ items }) => {
  const t = useTranslations()

  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-top">
          <div className="footer-award-heading">
            <RibbonLeft className="icon-ribbon-left" />
            <h2
              className="footer-award-title"
              dangerouslySetInnerHTML={{
                __html: t('noun:awards-recognitions'),
              }}
            />

            <RibbonRight className="icon-ribbon-right" />
          </div>
          <FooterAward
            title="Blue Tulip Awards"
            image={award1}
            subtitle="2021, Accenture, Top 5 Finalist"
            imageWidth={26}
            imageHeight={40}
          />
          <FooterAward
            title="Start-up Disruption Award"
            image={award2}
            subtitle="2019, University of Berkeley, Berkeley Haas"
            imageWidth={37}
            imageHeight={36}
          />
          <FooterAward
            title="Beautiful Software Awards"
            image={award3}
            subtitle="2019, W-Lounge Academy, 3rd Place in Education"
            imageWidth={21}
            imageHeight={39}
          />
        </div>
        <div className="footer-bottom">
          <div className="footer-sitemap">
            <SitemapBlock items={items.block1} />
            <SitemapBlock items={items.block2} />
            <SitemapBlock items={items.block3} />
            <SitemapBlock items={items.block4} />
            <SitemapBlock items={items.block5} />
            <div className="footer-sitemap-block extra-block">
              <p className="sitemap-item">© 2019-2022 AUCTA</p>
              <p className="sitemap-item">
                Made with
                <img
                  className="love"
                  src={heart}
                  alt="love"
                  size="12"
                  height="12"
                />
                <br />
                in Berlin and Madrid
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  items: PropTypes.object,
}

export default Footer

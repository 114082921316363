const footerItems = {
  block1: [
    { to: '/', name: 'noun:home' },
    { to: '/how-it-works', name: 'how-it-works:title' },
    { to: '/license', name: 'noun:pricing' },
    { to: '/free-trial', name: 'noun:free-trial' },
    { to: '/demo', name: 'action:try-online-demo-now' },
  ],

  block2: [
    { to: '/who-its-for', name: 'who-its-for:title' },
    { to: '/maintenance', name: 'noun:maintenance-servicing' },
    { to: '/sales', name: 'sales:name' },
    { to: '/customer-enablement', name: 'noun:customer-enablement' },
    { to: '/training', name: 'noun:trainig' },
  ],
  block3: [
    { to: '/case-studies', name: 'noun:case-studies' },
    { to: '/ceramtec', name: 'Ceramtec', literal: true },
    { to: '/alphr', name: 'Alphr', literal: true },
    { to: '/eos', name: 'EOS', literal: true },
  ],
  block4: [
    { to: '/why-us', name: 'noun:why-us' },
    {
      href: 'https://aucta.join.com/',
      name: 'noun:career',
      isExternal: true,
    },
    { to: '/blog', name: 'noun:blog' },
    { to: '/blog/news', name: 'noun:news' },
    { to: '/contact', name: 'noun:contact' },
    { to: '/status', name: 'noun:status', status: true },
  ],
  block5: [
    {
      to: '/blog/changelog',
      name: 'v 2.2 – Change log',
      literal: true,
    },
    {
      isExternal: true,
      href: 'https://www.crunchbase.com/organization/aucta',
      name: 'noun:crunchbase',
    },
    {
      isExternal: true,
      href:
        'https://drive.google.com/file/d/1j0NmBIxhMKiFXialIhGIf2UG1KpXeLpe/view',
      name: 'noun:press-kit',
    },
    { to: '/contact', name: 'noun:contact' },
    { to: '/imprint', name: 'noun:legal' },
    { to: '/data-privacy', name: 'noun:legal:privacy' },
  ],
}

export default footerItems
